export const environment = {
  name: 'development',
  production: true,
  insightKey: '0bd0ba74-05d5-4cf4-b142-221276474db2',
  domainPatientAPI: 'https://ofix-to-gtw-locked.azure-api.net/pat/v1',
  domainUserAPI: 'https://ofix-to-gtw-locked.azure-api.net/usr/v1',
  domainImageAPI: 'https://ofix-to-gtw-locked.azure-api.net/img/v1',
  domainRayAPI: 'https://ofix-to-gtw-locked.azure-api.net/ray/v1',
  domainTemplatesAPI: 'https://ofix-to-gtw-locked.azure-api.net/tpl/v1',
  sseAPI: 'https://ofix-to-gtw-locked.azure-api.net/sse',
  cdnUrl: 'https://cdndev.orthonext.app/dev',
  b2cTenantName: 'tlhexdevIDP',
  b2cTenantId: '8ccea850-ae88-4811-a8ea-cf0e7cbcc2ee',
  b2cSignInPolicyId: 'B2C_1_OnlySignIN2',
  b2cResetPasswordPolicyId: 'B2C_1_passwordreset1',
  b2cChangePasswordPolicyId: 'B2C_1A_PasswordChange',
  b2cClientId: '2c9ab21b-6126-42c8-9185-ff3b38996dbf',
  b2cApplicationScope: 'openid profile https://tlhexdevIDP.onmicrosoft.com/ofixdev/user_impersonation',
  appSSOutUriApi: 'https://ofix-to-gtw-locked.azure-api.net/services/appssouturi',
  idleTimeToEndSession: 1800,
  buildVer: 'OrthoNext-FitBone FE DEV CI-CD_24337.01',
  patplanSite: 'https://patdev.orthonext.app/list/plans',
  planDetailsSite: 'https://patdev.orthonext.app/plan/edit?planGuid=',
  nextraySite: 'https://nextraydev.orthonext.app',
  legalTermsSite: 'https://requestdev.orthonext.app/legal-terms',
  aboutSite: 'https://cdndev.orthonext.app/dev/about/about-en.html',
  guideSite: {
    baseUrl: 'https://cdndev.orthonext.app/dev/Guide',
    list: 'https://cdndev.orthonext.app/dev/Guide/guide_list.json'
  },
  helpSite: {
    baseUrl: 'https://cdndev.orthonext.app/dev/Help/FitBone10',
    fileNames: 'https://cdndev.orthonext.app/dev/Help/FitBone10/file_names.json'
  },
	eifuSite: 'https://ifu.orthofix.it',
	fitboneModelVersion: '1.0',
	cookieName: '_iub_cs-67063076'
};
